<script>
import MobileLayout from '@/components/thank-you/mobile/ThankYou'
import DesktopLayout from '@/components/thank-you/web/ThankYou'
import NpsThankYouLayout from '@/components/thank-you/partials/NpsThankYou'
import Vue from 'vue'

//TODO: change this to swap between the Mobile and Web version of the dynamic form.
// Just putting a note here since the form needs to be loaded 1 level above and passed through here
export default {
    functional: true,
    name: "SwitchLayout",
    render(createElement, context) {
        // can be removed AFTER adding in code to pass through the list of questions
        //checking if we have NPS (the route names are all in lower case)
        if (context.data?.attrs?.routeType?.indexOf('nps') > -1) return createElement(NpsThankYouLayout, context.data, context.children)
        if (Vue.$device.mobile) {
            // TODO: add back mobile site when it's ready
            return createElement(MobileLayout, context.data, context.children)
            // return createElement(ComingSoonLayout, context.data, context.children)
        }
        return createElement(DesktopLayout, context.data, context.children)
    }
}
</script>
