import {goBackToPresurvey} from '@/modules/forms'
import {FIELD_RESET_TIMEOUT, THANKYOU_SURVEY_TIMEOUT} from '@/modules/constants'
import {ref, watch} from '@vue/composition-api'
import SEND_FULL_SURVEY from '@/graphql/mutations/sendFullSurvey.gql'
import {provideApolloClient, useMutation} from '@vue/apollo-composable'
import { client } from '@/vue-apollo'


export function useTimeout(variables) {
    provideApolloClient(client)
    const previousRoute = ref(null)
    const redirectTimeout = ref(null)
    const isKiosk = ref(null)
    const shortName = ref(null)
    const params = ref(null)
    const router = ref(null)
    const completeSurveyForm = ref(null)
    const redirecting = ref(false)
    const email = ref(''), phone = ref(''), formAnswerId = ref(''), tenantId = ref('')
    const counter = ref(THANKYOU_SURVEY_TIMEOUT)

    const sendSurvey = async () => {
        return new Promise(async (resolve, reject) => {
            const { onError, mutate, onDone} = useMutation(
                SEND_FULL_SURVEY,
                () => {
                    return {
                        variables: {
                            email: email.value,
                            phone: variables.value?.phone,
                            tenant: variables.value?.tenantId,
                            formId: null,
                            formVersion: null,
                            formAnswerId: variables.value?.formAnswerId,
                            formSetId: '',
                            practitioner: ''
                        }
                    }
                }
            )
            onDone(({ data }) => {
                resolve(data)
            })
            onError(err => {
                reject(err)
            })
            await mutate()
        })
    }

    const callBackToPreSurvey = () => {
        if (redirecting.value) return

        redirecting.value = true
        const routeName = previousRoute.value
        goBackToPresurvey(router.value, isKiosk.value, shortName.value, params.value, routeName)
    }

    const runTimeout = () => {
        if (counter.value === null) counter.value = THANKYOU_SURVEY_TIMEOUT
        if (counter.value > 0) {
            redirectTimeout.value = setTimeout(async () => {
                counter.value--
                runTimeout()
            },  1000)
            return
        }
        callBackToPreSurvey()
    }

    const cancelTimeout = () => {
        if (!redirectTimeout.value) return

        clearTimeout(redirectTimeout.value)
        redirectTimeout.value = null
        counter.value = null
    }

    const fieldResetTimeout = () => {
        clearTimeout(redirectTimeout.value)
        redirectTimeout.value = null
        counter.value = FIELD_RESET_TIMEOUT
        runTimeout()
    }

    watch(() => variables.value, (newValue) => {
        email.value = newValue?.email
        phone.value = newValue?.phone
        formAnswerId.value = newValue?.formAnswerId
        tenantId.value = newValue?.tenantId
    }, { deep: true })

    return {
        previousRoute,
        redirectTimeout,
        isKiosk,
        shortName,
        params,
        router,
        completeSurveyForm,
        counter,
        callBackToPreSurvey,
        runTimeout,
        cancelTimeout,
        sendSurvey,
        fieldResetTimeout
    }
}
